import { template as template_a8e03e6401f4478b8bc970ed1f13f881 } from "@ember/template-compiler";
const WelcomeHeader = template_a8e03e6401f4478b8bc970ed1f13f881(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
