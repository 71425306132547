import { template as template_c85cb236a82d4b478e1640e4263a7aad } from "@ember/template-compiler";
const FKControlMenuContainer = template_c85cb236a82d4b478e1640e4263a7aad(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
