import { template as template_f9f1b6ec4b8e49a2b1546d07d2a46455 } from "@ember/template-compiler";
const FKText = template_f9f1b6ec4b8e49a2b1546d07d2a46455(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
