import { template as template_617066fa3c734518ac9e74ea2601b5eb } from "@ember/template-compiler";
const SidebarSectionMessage = template_617066fa3c734518ac9e74ea2601b5eb(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
